import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {DefaultService} from './@core/services/default.service';
import {CompanyInit} from './@core/interfaces/company-init';
import {NavigationEnd, Router} from '@angular/router';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {LocalStorageService} from './@core/services/local-storage.service';
import {AuthStorageService} from './@core/services/auth/auth-storage.service';
import {AuthService} from './@core/services/auth/auth.service';
import {CompanyConf} from './@core/interfaces/company-conf';
import {CssAttributes} from './@core/interfaces/CssAttributes';
import {Meta, Title} from '@angular/platform-browser';
import {getWindow} from "ssr-window";
import {WebEstaticaService} from './@core/services/web-estatica/web-estatica.service';
import {Posicionamiento} from './@core/interfaces/posicionamiento';
import {leerEmpresaInfo, obtenerEmpresaInfoTotal} from './redux/empresa.actions';
import {Store} from '@ngrx/store';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private defaultService: DefaultService, private router: Router,
              private localStorageService: LocalStorageService, private authStorageService: AuthStorageService,
              private authService: AuthService, private titleService: Title, private webEstaticaService: WebEstaticaService,
              private metaService: Meta, @Inject(DOCUMENT) private _document: Document, private storeEmpresa: Store<any>) {
    this.storeEmpresa.dispatch(leerEmpresaInfo());
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.posicionamiento != undefined) {
          this.cargarPosicionamiento();
        }
      }
    });
  }


  connection = true;
  name = '';
  loading = true;
  link: string;
  tokenDateTime: Date | undefined;

  companyConf: CompanyConf;
  posicionamiento: Posicionamiento | undefined;
  fontUrl: string | undefined;
  mostrarFondo = false;
  innerWidth = getWindow().innerWidth;

  ngOnInit(): void {
    this.agregarPosicionamiento();
    this.obtenerDatosEmpresa();

    this.refreshToken();
    this.getCompanyData();

    this.defaultService.getSCSSAttributes().subscribe((data: CssAttributes) => {
      this._document.documentElement.style.setProperty('--primary-color', data.primary);
      this._document.documentElement.style.setProperty('--secondary-color', data.secondary);
      this._document.documentElement.style.setProperty('--danger-color', data.danger);
      this._document.documentElement.style.setProperty('--font-style', data.font);
      this._document.documentElement.style.setProperty('--text-color', data.textPrimary);
      this._document.documentElement.style.setProperty('--text-secondary', data.textSecondary);
      this._document.documentElement.style.setProperty('--background-color', data.colorFondo);
      this._document.documentElement.style.setProperty('--primary-light-color', data.primary + '50');

      this.fontUrl = data.fontUrl;
      this.mostrarFondo = data.mostrarFondo;
      this._document.documentElement.style.setProperty('--font', data.font);
    });
  }

  validateUser() {
    this.authService.validateToken().subscribe(
      (result: any) => {
        const date = result.value.split(" ")[0].split("/");
        const time = result.value.split(" ")[1].split(":");
        this.tokenDateTime = new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]);
      },
      () => {
        this.authStorageService.logOut();
      }
    );
  }

  refreshToken() {
    if (this.authStorageService.getUser() != null) {
      if (this.tokenDateTime == undefined) {
        this.validateUser();
      } else {
        const now = new Date();
        const oneHour = new Date(this.tokenDateTime.getTime() - (60 * 60000));
        if (now > oneHour && now < this.tokenDateTime) {
          this.authService.refreshToken().subscribe(
            (result: any) => {
              this.authStorageService.setToken(result.token);
              const date = result.datetime.split(" ")[0].split("/");
              const time = result.datetime.split(" ")[1].split(":");
              this.tokenDateTime = new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]);
            }
          )
        }
      }
    }
  }

  getCompanyData() {
    this.defaultService.validConnection().subscribe(
      (data: CompanyInit) => {
        this.name = data.name;
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.connection = false;
      });


  }

  onChildLoaded() {
    this.cargarPosicionamiento();
  }

  agregarPosicionamiento() {
    this.webEstaticaService.getPosicionamiento().subscribe((posicionamiento: any) => {
      this.posicionamiento = posicionamiento;
      this.cargarPosicionamiento();
    });
  }

  cargarPosicionamiento() {
    if (this.posicionamiento != undefined) {
      if (!this.router.url.includes('/compra')) {
        this.limpiarPosicionamiento();
        let title = this.posicionamiento.Title;
        let descripcion = this.posicionamiento.Description;

        if (this.router.url.includes('acerca-de')) {
          title = 'Conoce nuestra historia | ' + this.posicionamiento.Title;
        } else if (this.router.url.includes('atencion-al-cliente')) {
          title = 'Contacta con Nosotros | ' + this.posicionamiento.Title;
          descripcion = "¿Tienes preguntas o comentarios? Estamos aquí para ayudarte. Contacta con nosotros de manera fácil y rápida." +
            " Nuestro equipo de atención al cliente está listo para responder tus consultas y brindarte asistencia. " +
            "¡No dudes en ponerte en contacto con nosotros hoy mismo!"
        } else if (this.router.url.includes('iniciarSesion')) {
          title = 'Inicia Sesión | ' + this.posicionamiento.Title;
          descripcion = "Accede a tu cuenta de manera segura y rápida. Inicia sesión en nuestra plataforma y disfruta de una experiencia " +
            "personalizada. Gestiona tus datos y disfruta de nuestros servicios con un solo clic. ¡Bienvenido de vuelta!"
        } else if (this.router.url.includes('registrarse')) {
          title = 'Registrate con nosotros | ' + this.posicionamiento.Title;
          descripcion = "Únete a nuestra comunidad y regístrate en minutos. Crea tu cuenta personalizada y disfruta de múltiples ventajas. " +
            "¡Empieza tu viaje con nosotros hoy mismo y descubre todas las ventajas que tenemos para ofrecerte!"
        } else if (this.router.url.includes('carrito')) {
          title = 'Tramita tu pedido | ' + this.posicionamiento.Title;
          descripcion = "Completa tu pedido de forma sencilla y rápida. Inicia el proceso de tramitación de tu compra en nuestra plataforma" +
            " y disfruta de nuestros productos y servicios de calidad. " +
            "¡Haz tu pedido ahora y experimenta la comodidad de comprar con nosotros!"
        } else if (this.router.url.includes('aviso-legal')) {
          title = 'Aviso Legal | ' + this.posicionamiento.Title;
          descripcion = "Descubre nuestros términos y condiciones legales. Lee nuestro aviso legal para conocer tus derechos " +
            "y responsabilidades al utilizar nuestros servicios. Información importante sobre privacidad, cookies y más.";
        } else if (this.router.url.includes('politica-privacidad')) {
          title = 'Política de privacidad | ' + this.posicionamiento.Title;
          descripcion = "Lee nuestra política de privacidad para proteger tus datos personales. Descubre cómo cuidamos tu información" +
            " y tu privacidad mientras disfrutas de nuestros servicios. Tu confianza es nuestra prioridad.";
        } else if (this.router.url.includes('política-devolucion-y-envio')) {
          title = 'Política de devoluciones y envíos | ' + this.posicionamiento.Title;
          descripcion = "Conoce nuestra política de devolución y envío. Obtén información detallada sobre cómo gestionamos tus pedidos y " +
            "devoluciones. Tu satisfacción es nuestra prioridad, descubre cómo cuidamos de tus compras.";
        } else if (this.router.url.includes('aviso-cookies')) {
          title = 'Información sobre Cookies | ' + this.posicionamiento.Title;
          descripcion = "Consulta nuestro aviso de cookies para entender cómo utilizamos las cookies y tecnologías similares en nuestro " +
            "sitio web. Aprende cómo estas herramientas mejoran tu experiencia de usuario y privacidad en línea.";
        }
        this.titleService.setTitle(title);
        this.metaService.addTag({name: 'description', content: descripcion});
        this.metaService.addTag({name: 'og:title', content: title});
        this.metaService.addTag({name: 'og:description', content: descripcion});
        this.metaService.addTag({name: 'og:image', content: this._document.URL + '/assets/logo.png'});
        this.metaService.addTag({name: 'og:locale', content: this.posicionamiento.Locale});
        this.metaService.addTag({name: 'og:url', content: this._document.URL});
        this.metaService.addTag({name: 'keywords', content: this.posicionamiento.KeyWords});
        this.metaService.addTag({name: 'og:site_name', content: this.posicionamiento.Name});
        this.metaService.addTag({name: 'name', content: this.posicionamiento.Name});
      }
    }
    this.metaService.addTag({name: 'author', content: 'Soft Control Informática, S.A.'});
    this.metaService.addTag({name: 'copyright', content: '© 2023, Soft Control Informática, S.A.'});
    this.metaService.addTag({name: 'robots', content: 'index, follow'});
    this.metaService.addTag({name: 'googlebot', content: 'index, follow'});

    const els = this._document.querySelectorAll('link[rel=\'canonical\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }

    let link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this._document.head.appendChild(link);
    let url = this._document.URL;
    if (url.includes('inicio')) {
      url = url.replace('/', '');
    }
    link.setAttribute('href', url);
  }


  limpiarPosicionamiento() {
    this.metaService.removeTag("name='og:title'");
    this.metaService.removeTag("name='description'");
    this.metaService.removeTag("name='og:site_name'");
    this.metaService.removeTag("name='og:url'");
    this.metaService.removeTag("name='name'");
    this.metaService.removeTag("name='author'");
    this.metaService.removeTag("name='keywords'");
    this.metaService.removeTag("name='robots'");
    this.metaService.removeTag("name='og:locale'");
    this.metaService.removeTag("name='og:type'");
    this.metaService.removeTag("name='og:image'");
    this.metaService.removeTag("name='og:description'");
    this.metaService.removeTag("name='product:price:currency'");
    this.metaService.removeTag("name='product:price:amount'");
    this.metaService.removeTag("name='product:availability'");
  }

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = getWindow().innerWidth;
  }


  /*_____FUNCIONES REDUX---------*/
  obtenerDatosEmpresa() {
    this.storeEmpresa.select('empresaInfoTotal').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.companyConf = value;
          } else {
            this.defaultService.getCompanyConfig().subscribe((value: any) => {
              this.storeEmpresa.dispatch(obtenerEmpresaInfoTotal({ value }));
              this.companyConf = value;
            });
          }
        });
  }

}
