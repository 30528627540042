<div *ngIf="this.fontUrl != undefined">
  <link [href]="fontUrl | safe" rel="stylesheet">
</div>


<div *ngIf="loading; else elseConnection" class="align-items-center d-flex justify-content-center container-load">
  <div class="w-100">
    <div class="w-100 d-flex justify-content-center mb-3">
      <img src="assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos" style="height: 150px !important; width: auto !important;"/>
    </div>
    <div class="text-center">
      <h3>Estamos cargando todos los datos</h3>
      <p style="font-size: 14px">Espere un momento</p>
    </div>
  </div>
</div>

<ng-template #elseConnection>
  <div *ngIf="!mostrarFondo">
    <div *ngIf="connection; else elseNoConnection" class="bg-white">
      <router-outlet (activate)="onChildLoaded()"></router-outlet>
    </div>
  </div>

  <div *ngIf="mostrarFondo" class="imagen-fondo">
    <div *ngIf="connection; else elseNoConnection" class="bg-white">
      <router-outlet (activate)="onChildLoaded()"></router-outlet>
    </div>
  </div>
</ng-template>

<ng-template #elseNoConnection>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="w-75 card">
      <h1 class="text-danger w-100 text-center">Ha ocurrido un error.</h1>
      <h3 class="font-weight-bold w-100 text-center">En estos momentos la conexión con el servidor no se encuentra disponible.</h3>
    </div>
  </div>
</ng-template>

<!-- botão flutuante whatsapp -->
<div *ngIf="companyConf != undefined">
  <div *ngIf="companyConf.whatsAppPhone != undefined">
    <a class="position-fixed"
       [ngClass]="{'whatsapp-mobile': innerWidth < 990, 'whatsapp-computer': innerWidth >= 990}"
       href="https://api.whatsapp.com/send?phone={{companyConf.whatsAppPhone}}" >
      <img src="https://i.ibb.co/VgSspjY/whatsapp-button.png" alt="Botón de whatsapp">
    </a>
  </div>
</div>
